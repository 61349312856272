import styled from '@emotion/styled';

export const ContactWrapper = styled('div')`
  flex-grow: 1;
  background: #171717;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::scrollbar {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    overflow: hidden;
  }
`;

export const ContactNav = styled('div')`
  flex-shrink: 0;
  padding: 41px 118px;
  font-size: 0;
  line-height: 17px;

  a {
    text-decoration: none;
    color: #ffffff;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    margin-right: 8px;
    width: 18px;
    height: 11px;
    vertical-align: middle;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    vertical-align: top;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.36rem 0.24rem;
  }
`;

export const ContactMain = styled('div')`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  padding-top: 88px;
  width: 1195px;
  overflow: hidden auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .side {
    width: 369px;
    font-style: normal;
    .title {
      padding-left: 48px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ff5f34;
    }
    .question {
      margin-top: 16px;
      padding-left: 48px;
      font-weight: 700;
      font-size: 2px;
      line-height: 25px;
      letter-spacing: 0.055em;
      text-transform: uppercase;
      color: #ffffff;
    }
    .information {
      margin-top: 32px;
      padding-left: 48px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      color: rgba(255, 255, 255, 0.8);
    }
    .image {
      margin-top: 50px;
      width: 369px;

      img {
        width: 100%;
      }
    }
  }
  .frame {
    margin: 0;
    width: 687px;
    .gorgias {
      min-height: 900px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: calc(100% + 18px);
      &::-webkit-scrollbar {
        display: none;
      }
      &::scrollbar {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    display: block;
    margin: 0;
    width: 100%;
    padding: 0 0.24rem;
    .side {
      display: none;
    }
    .frame {
      width: 100%;
    }
  }
`;
