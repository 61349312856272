import * as React from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import ThemeProvider from '../components/theme/themeProvider';
import { ContactWrapper, ContactNav, ContactMain } from '../components/styles/Contact';
import contactBackImg from '../components/images/contact_back.svg';
import contactSideImg from '../components/images/contact_side.png';

const Contact = ({ location, isDarkThemeActive, toggleActiveTheme }) => {
  const [history, setHistory] = React.useState(null);
  React.useEffect(() => {
    window && setHistory(window.history);
    document.body.classList.add("dark");
  }, []);
  return (
    <ThemeProvider>
      <Helmet>{<title>Contact</title>}</Helmet>
      <ContactWrapper>
        {history && history.length > 1 && (
          <ContactNav>
            <a
              href="#"
              onClick={() => {
                history.back();
              }}
            >
              <img src={contactBackImg} />
              <span className="back_text">BACK</span>
            </a>
          </ContactNav>
        )}
        <ContactMain>
          <div className="side">
            <div className="title">Contact Us</div>
            <div className="question">Still have questions?</div>
            <div className="information">Get in touch with us for more information.</div>
            <div className="image">
              <img src={contactSideImg} />
            </div>
          </div>
          <div className="frame">
            <iframe
              src="https://viture.gorgias.help/en-US/embed/contact"
              className="gorgias"
              width="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </ContactMain>
      </ContactWrapper>
    </ThemeProvider>
  );
};

export default Contact;
